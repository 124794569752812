import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { RecoilRoot } from 'recoil'
import ForgotPassword from './views/pages/Home/ForgotPassword'
import ValidatePassword from './views/pages/Home/ValidatePassword'
import ConfirmNewPassword from './views/pages/Home/confirmNewPassword'
import Dashboard from './components/CollegeLogin/Dashboard'
import ChangePassword from './views/pages/changePassword/ChangePassword'

import StudentRegisteration from './components/StudentRegisteration/StudentRegisteration'

import RegistrationInstruction from './views/pages/Home/RegistrationInstruction'
import OnboardingRegistration from './views/pages/Home/OnboardingRegistration'
import RegistrationDetails from './views/pages/Home/RegistrationDetails'
import OtpScreen from './views/pages/Home/OtpScreen'
import RegistrationValidation from './views/pages/Home/RegistrationValidation'
import PrivateRoute from '../src/PrivateRoute'
import RoleManagement from './components/Admin/RoleManagement'
import ScreenMappingRou from './components/Admin/ScreenMapping'

import Tostify from './utils/Tostify'
import FirstTimeChangePassword from './views/pages/changePassword/FirstTimeChangePassword'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const ChangePassword = React.lazy(() => import('./views/pages/changePassword/ChangePassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
// const RequestedCourse = React.lazy(() => import('./views/pages/course/RequestedCourse'))
const Register = React.lazy(() => import('./views/pages/registration/registration'))
const Instruction = React.lazy(() => import('./views/pages/registration/instruction'))
const Homepage = React.lazy(() => import('./views/pages/Home/Homepage'))
class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Suspense fallback={loading}>
            <RecoilRoot>
              <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="/500" element={<Page500 />} />
                <Route path="/register" element={<Register />} />
                <Route path="/homepage" element={<Homepage />} />
                <Route path="/" element={<DefaultLayout />}>
                  <Route index name="Dashboard Page" element={<Dashboard />} />
                  <Route
                    path="/change-password"
                    name="Change Password Page"
                    element={<ChangePassword />}
                  />
                  <Route
                    path="/student-registeration"
                    name="Student Registeration Page"
                    element={<StudentRegisteration />}
                  />
                  <Route
                    path="/Role-Management"
                    name="Role Management"
                    element={<RoleManagement />}
                  />
                  <Route
                    path="/Screen-Mapping"
                    name="Screen Mapping"
                    element={<ScreenMappingRou />}
                  />
                </Route>

                <Route path="/login" name="Login Page" element={<Login />} />
                <Route
                  path="/forgot-password"
                  name="Forgot Password Page"
                  element={<ForgotPassword />}
                />
                <Route
                  path="/validate-password"
                  name="Validate Password Page"
                  element={<ValidatePassword />}
                />

                <Route
                  path="/confirm-new-password"
                  name="Confirm New Password Page"
                  element={<ConfirmNewPassword />}
                />

                <Route path="/homepage" name="Homepage" element={<Homepage />} />
                <Route
                  path="/registration-instruction"
                  name="Registration Instruction"
                  element={<RegistrationInstruction />}
                />
                <Route
                  path="/onboarding-registration"
                  element={
                    <PrivateRoute>
                      <OnboardingRegistration />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/registration-details"
                  name="Registration Details"
                  element={<RegistrationDetails />}
                />
                <Route path="/otp-screen" name="OTP Screen" element={<OtpScreen />} />
                <Route
                  path="/registration-validation"
                  name="Registration Validation"
                  element={<RegistrationValidation />}
                />
                <Route path="/ChangePassword" element={<FirstTimeChangePassword />} />
                <Route path="*" element={<DefaultLayout />} />
              </Routes>
            </RecoilRoot>
          </Suspense>
        </Router>
        <Tostify />
      </>
    )
  }
}

export default App
