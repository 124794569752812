export async function collegeAllocationSearch(queryParams) {
    const queryString = Object.keys(queryParams)
      .map((key) => {
        if (queryParams[key]) {
          return `${key}=${queryParams[key]}`
        }
        return ''
      })
      .filter((value) => value !== '')
      .join('&')
    try {
      const token = localStorage.getItem('token')
      // console.log(queryString)
      const rawResponse = await fetch(
        process.env.REACT_APP_API + `/v1/api/fetch/college-listing?${queryString}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const content = await rawResponse.json()
      if (rawResponse.status === 200) {
        return { body: content.body, success: true, message: '' }
      } else {
        return { body: [], success: false, message: content.message }
      }
    } catch (e) {
      console.error(e)
      return { data: undefined, success: false, message: 'Somthing went wrong !' }
    }
  }




  export async function allocationDashboard() {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/collegeAssociationList`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }
  



  export async function allocationDashboardById(id) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/get/collegeAssociateDeails?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }


  export async function updateCandidateReporting(formData) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/update/collegeAssociateDeails`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }




  
  export async function reporteGenrations() {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/get/all/collegeListingReport`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }