import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import { fetchCandidatesMilestone } from 'src/services/StudentRegisteration/StudentRegisterationServices'
// import { AlertComponent } from '../../../src/services/Common/AlertComponent'
import { ErrorScreen } from '../../views/pages/ErrorScreen'

const StudentRegisteration = () => {
  const [candidateMilestone, setCandidateMilestone] = useState([])
  const [loader, enableLoader] = useRecoilState(loadingState)
  const [error, setError] = useState(null)
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  const navigateTo = () => {
    navigate('/registerationPage')
  }

  const fetchCandidateMilestone = async () => {
    enableLoader(true)
    try {
      const response = await fetchCandidatesMilestone()
      if (response.success) {
        setCandidateMilestone(response.body)
        if (response.body[9]?.submitted) {
          navigate('/submitPage')
        }
      } else {
        setAlertMessage(response.message || 'An error occurred.')
        setAlertVisible(true)
      }
    } catch (error) {
      setAlertMessage('An error occurred.')
      setAlertVisible(true)
    } finally {
      enableLoader(false)
    }
  }

  useEffect(() => {
    fetchCandidateMilestone()
  }, [])

  return (
    <>
      {alertVisible ? (
        <div className="App">
          <ErrorScreen errorMessage={alertMessage} />
        </div>
      ) : (
        <>
          <div className="card">
            <div className="card-header text-white" style={{ backgroundColor: '#3C4B64' }}>
              <div className="d-flex align-items-center justify-content-between">
                <span className="mr-auto">Student Registration</span>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Milestone ID</th>
                    <th scope="col">Milestone Details</th>
                    <th scope="col">Milestone Status</th>
                  </tr>
                </thead>
                <tbody>
                  {candidateMilestone.map((milestone, index) => (
                    <tr key={index}>
                      <th scope="row">Milestone {index + 1}</th>
                      <td>{milestone?.milestone?.milestoneType}</td>
                      <td>
                        <button
                          type="button"
                          className={`btn btn-sm text-white me-2 ${
                            milestone?.submitted ? 'btn-success' : 'btn-danger'
                          }`}
                        >
                          {milestone?.submitted ? 'Complete' : 'InComplete'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="btn btn-sm text-white btn-info d-block mx-auto mt-3"
                onClick={() => {
                  navigateTo()
                }}
              >
                Proceed to fill / Edit form
              </button>
            </div>
          </div>
          <div className="card mt-3">
            <p className="ms-3 mt-3">
              <b>Instructions to fill the Application Form: </b>
            </p>
            <ol>
              <li>
                Candidate must read the Notification(s) for the session 2024-25 carefully before
                finally submitting the application form.
              </li>
              <li>
                Any concealment of information or wrong information if given by the Candidate, shall
                lead to cancellation of candidature without any further notice.
              </li>
              <li>
                For any other information, candidates are advised to visit the website of
                Pt.BD.Sharma, University (<a href="https://www.uhsr.ac.in/">www.uhsr.ac.in</a>) and
                official website of Department of Medical Education and Research, Government of
                Haryana (<a href="https://www.dmer.haryana.gov.in/">www.dmer.haryana.gov.in</a>).
              </li>
              <li>
                Please take note that the above mentioned details will be verified from DGHS,
                Haryana. Department of Medical Education and Research will not be responsible if
                your In-service records are not received from the DGHS, Haryana.
              </li>
            </ol>
            <p className="ms-3">
              <b>Note: </b>One year compulsory rotatory internship shall be an essential
              pre-qualification for eligibility of admission to MD/MS/MDS/Post MBBS DNB courses for
              the academic session 2024-25. The cut off date for completion of internship shall be
              11th August 2024.
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default StudentRegisteration
