import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import logo from '../../../assets/images/logo.svg'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useRecoilState } from 'recoil'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import Tostify, { showToast } from 'src/utils/Tostify'

import { registerCandidateDetails } from 'src/services/Registration/RegisterationService'
import Header from 'src/components/header/Header'

const RegistrationDetails = () => {
  let counsellingName = useRecoilValue(globalStream).counsellingName

  const location = useLocation()
  const { neetRollNumber, email, phoneNumber, counsellingId } = location.state || {}
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  useEffect(() => {
    if (neetRollNumber || email || phoneNumber || counsellingId) {
      setFormData({
        registrationNo: neetRollNumber || '',
        phoneNumber: phoneNumber || '',
        email: email || '',
        counsellingId: counsellingId || 1,
      })
    }
  }, [neetRollNumber, email, phoneNumber, counsellingId])
  const [formData, setFormData] = useState({
    id: null,
    phoneNumber: '',
    email: '',
    registrationNo: '',
    counsellingId: '',
  })
  const [validationErrors, setValidationErrors] = useState({
    registrationNo: '',
    phoneNumber: '',
    email: '',
  })
  const [loader, enableLoader] = useRecoilState(loadingState)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const sendOtpAll = async () => {
    enableLoader(true)
    const SendOtp = async () => {
      setIsSubmitting(true)
      enableLoader(true)
      var response = await registerCandidateDetails(formData)
      if (response.success) {
        setIsSubmitting(false)
        const id = response.body.id
        navigate('/otp-screen', { state: { id } })
        enableLoader(false)
        setIsSubmitting(false)
      } else {
        setIsSubmitting(false)
        enableLoader(false)
        showToast(response.message, 'error')
      }
    }
    SendOtp()
  }

  const handleSubmit = () => {
    sendOtpAll()
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  

  return (
    <>
      <div className="container-fluid" style={{ padding: '10px 20px' }}>
        <Header />
        <div
          className="mt-2 d-flex justify-content-center align-items-center"
          style={{ fontWeight: 'bold', fontSize: '25px' }}
        >
          Online Application Form For Admission To{' '}
          {GSteam.counsellingName === 'UG' ? 'NEET UG' : 'AIAPGET'} courses 2024-25
        </div>

        <div className="card ms-5 me-5 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 d-flex justify-content-strt">
                <th>Verification of Communication Details</th>
              </div>
            </div>
            <hr />
            <div className="row mt-2">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col">
                    <label htmlFor="Email">Email:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Email"
                      name="email"
                      value={formData?.email}
                      onChange={handleInputChange}
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      onKeyDown={(e) =>
                        /[a-zA-Z0-9._%+-@]/.test(e.key) ||
                        e.key === 'Backspace' ||
                        e.preventDefault()
                      }
                      onInput={(e) =>
                        e.target.value.length > 50 && (e.target.value = e.target.value.slice(0, 50))
                      }
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="phoneNumber">Mobile Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="  "
                      name="phoneNumber"
                      value={formData?.phoneNumber}
                      onChange={handleInputChange}
                      pattern="^[0-9]{1,10}+$"
                      onKeyDown={(e) =>
                        /[0-9]{1,10}/.test(e.key) || e.key === 'Backspace' || e.preventDefault()
                      }
                      onInput={(e) =>
                        e.target.value.length > 10 && (e.target.value = e.target.value.slice(0, 10))
                      }
                    />
                  </div>
                </div>

                <div className="d-flex flex-column align-items-left">
                  <label style={{ color: 'red' }}>
                    <tr>
                      1. Same One Time Password (OTP) will be sent to the above mentioned email and
                      mobile number.
                    </tr>
                    <tr>
                      2. Kindly make sure that mobile number is free from Do Not Disturb (DND)
                      Facility.
                    </tr>
                  </label>
                </div>
                <hr />
                <div className="m-3 text-left form-group">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success text-white"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tostify />
    </>
  )
}

export default RegistrationDetails
