import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { loadingState } from 'src/atom/LoadingState'
import logo from '../../../assets/images/logo.svg'
import { useRecoilState } from 'recoil'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import Tostify, { showToast } from 'src/utils/Tostify'
import { validateCandidate } from 'src/services/Registration/RegisterationService'
import {
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import Header from 'src/components/header/Header'
import { getCutOffDetails, verifyRollNo } from 'src/services/PreRegistration/PreRegistration'

const OnboardingRegistration = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState()
  const [loader, enableLoader] = useRecoilState(loadingState)
  const [loading, setLoading] = useState(false)
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const [cutOff, setCutOff] = useState([])
  const counsellingNameFromGlobalStream = useRecoilValue(globalStream).counsellingName
  const [counsellingName, setCounsellingName] = useState(counsellingNameFromGlobalStream)
  const [scoreCard, setScoreCard] = useState('')

  useEffect(() => {
   
    if (counsellingName === 'PG') {
      setScoreCard('AIAPGET 2024')
    } else if (counsellingName === 'UG') {
      setScoreCard('NEET-UG 2024')
    }
  }, [counsellingName])

  const navigate = useNavigate()
  useEffect(() => {
    
    if (!counsellingName) {
      navigate('/homepage')
    }
  }, [counsellingName, navigate])

  useEffect(() => {
    fetchCutOffDetails()
  
    if (!counsellingName) {
      navigate('/homepage')
    }
  }, [counsellingName])

  const candidateObj = {
    registrationNo: '',
    name: '',
    counsellingName: '',
    dob: '',
  }
  const [formData, setFormData] = useState(candidateObj)
  const [validationErrors, setValidationErrors] = useState({
    registrationNo: '',
    name: '',
    counsellingName: '',
    dob: '',
  })

  const validateForm = () => {
    let errors = {}
    let isValid = true
    if (
      !formData.registrationNo ||
      formData.registrationNo === null ||
      formData.registrationNo === ''
    ) {
      errors.registrationNo = 'Please provide roll number'
      isValid = false
    }
    if (!formData.name || formData.name === null || formData.name === '') {
      errors.name = 'Please provide name'
      isValid = false
    }
    if (counsellingName === 'UG' && !formData.dob) {
      errors.dob = 'Please provide date of birth'
      isValid = false
    }
    setValidationErrors(errors)
    return isValid
  }

  const fetchCutOffDetails = async () => {
    enableLoader(true)
    const fetchAllCutOff = async () => {
      setData(true)
      var response = await getCutOffDetails(counsellingName)
      if (response.success) {
        setData(false)
        setCutOff(response.body)
        enableLoader(false)
      } else {
        setData(false)
        enableLoader(false)
        showToast(response.message)
      }
    }
    fetchAllCutOff()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = validateForm()
    setIsSubmitting(true)
    if (isValid) {
      const updatedFormData = { ...formData, counsellingName: counsellingName }
      enableLoader(true)
      const response = await validateCandidate(updatedFormData)
      if (response.success) {
        // console.log(response)
        // console.log(counsellingName)
        const newGlobalSteam = {
          neetRollNumber: response.body?.registrationNo,
          email: response.body?.email,
          phoneNumber: response.body?.phoneNumber,
          counsellingId: response.body?.counsellingId,
          counsellingName: counsellingName,
        }
        setGlobalSteam(newGlobalSteam)
        setLoading(true)
        enableLoader(false)
        setIsSubmitting(false)
        navigate('/registration-details', { state: { ...newGlobalSteam } })
        setVisible(false)
      } else {
        setIsSubmitting(false)
        showToast(response.message, 'error')
        enableLoader(false)
      }
    }
  }

  const handleKeyPress = (e) => {
    const isValidInput = /^\d$/.test(e.key)
    if (!isValidInput) {
      e.preventDefault()
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  console.log(`On Boarding Registration : ${GSteam.counsellingName}`)
  return (
    <>
      <div className="container-fluid" style={{ padding: '10px 20px' }}>
        <Header />
        <div
          className="mt-2 d-flex justify-content-center align-items-center"
          style={{ fontWeight: 'bold', fontSize: '25px' }}
        >
          Online Application Form For Admission To{' '}
          {GSteam?.counsellingName === 'UG' ? 'NEET-UG ' : 'AIAPGET '} courses 2024-25
        </div>
        <div className="card ms-5 me-5 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <th>
                  {GSteam?.counsellingName === 'UG' ? 'NEET UG 2024' : 'AIAPGET 2024'} Details
                </th>
              </div>
            </div>
            <hr />
            <div className="container">
              <div className="container mt-4">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  validationErrors?.name ? 'is-invalid' : ''
                                }`}
                                id="name"
                                placeholder="Enter Name..."
                                name="name"
                                value={formData?.name}
                                onChange={handleInputChange}
                                pattern="^[a-zA-Z ]+$"
                                title="Name should only contain letters and spaces"
                                // onKeyDown={(e) => !/[0-9]/.test(e.key) || e.preventDefault()}
                                onKeyDown={(e) => /[^a-zA-Z ]/.test(e.key) && e.preventDefault()}
                              />
                              {validationErrors?.name && (
                                <div className="invalid-feedback">{validationErrors?.name}</div>
                              )}
                            </div>
                          </div>
                          {counsellingName === 'UG' && (
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <input
                                  type="date"
                                  className={`form-control ${
                                    validationErrors?.dob ? 'is-invalid' : ''
                                  }`}
                                  id="dob"
                                  name="dob"
                                  value={formData?.dob}
                                  onChange={handleInputChange}
                                  max={new Date().toISOString().split('T')[0]}
                                  pattern="\d{4}-\d{2}-\d{2}"
                                  title="Date should be in the format YYYY-MM-DD"
                                />
                                {validationErrors?.dob && (
                                  <div className="invalid-feedback">{validationErrors?.dob}</div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <label htmlFor="registrationNo">
                                {GSteam?.counsellingName === 'UG' ? 'NEET UG 2024' : 'AIAPGET 2024'}{' '}
                                Roll Number
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  validationErrors?.registrationNo ? 'is-invalid' : ''
                                }`}
                                id="registrationNo"
                                placeholder="Enter Roll Number..."
                                name="registrationNo"
                                value={formData?.registrationNo}
                                onChange={handleInputChange}
                                pattern="^[0-9]+$"
                                onKeyDown={(e) =>
                                  /[0-9]/.test(e.key) || e.key === 'Backspace' || e.preventDefault()
                                } // allow only numeric and backspace
                              />
                              {validationErrors.registrationNo && (
                                <div className="invalid-feedback">
                                  {validationErrors?.registrationNo}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary text-white  btn-block mt-4"
                              onClick={handleSubmit}
                              disabled={
                                isSubmitting ||
                                !formData.registrationNo ||
                                !formData.name ||
                                (counsellingName === 'UG' && !formData.dob)
                              }
                            >
                              {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 ms-3 row mt-2">
              <div className="col-sm-12">
                <th>Important Instruction:-</th>
                <ul>
                  <li>
                    Candidate are required to fill details as per{' '}
                    {GSteam?.counsellingName === 'UG' ? 'NEET-UG ' : 'AIAPGET '} Score Card.
                  </li>
                  <li>
                    No rectification in the basic data filled during{' '}
                    {GSteam?.counsellingName === 'UG' ? 'NEET-UG ' : 'AIAPGET '} registration will
                    be allowed by the system.
                  </li>
                </ul>
                <hr />

                <th>
                  Minimum {scoreCard} Eligibility Criteria for Admission to{' '}
                  {GSteam?.counsellingName === 'UG' ? 'NEET-UG ' : 'AIAPGET '} course:
                </th>

                <div className="ms-3 form-check">
                  <label className="ms-2 form-check-label">
                    The Minimum eligibility criteria for admission to
                    {GSteam?.counsellingName === 'UG'
                      ? ' BAMS/BHMS courses is as per Information Bulletin NEET (UG)- 2024 '
                      : ' MD/MS (Ayurveda) course is issued by AACCC, Ministry of Ayush, Govt. of India time to time '}
                    . The Last eligibility criteria is mentioned as below -
                  </label>
                </div>

                <div className="card">
                  <div className="card-body">
                    {data == true ? (
                      enableLoader(true)
                    ) : (
                      <CCol xs={12}>
                        <CTable striped responsive>
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell scope="col">Category</CTableHeaderCell>
                              <CTableHeaderCell scope="col">
                                {GSteam?.counsellingName === 'UG' ? 'NEET-UG ' : 'AIAPGET '}
                                2024 CUT-off percentile/Score as per Qualifying Criteria
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {cutOff.length > 0 ? (
                              cutOff.map((data, index) => {
                                return (
                                  <CTableRow key={index} style={{ height: '10px' }}>
                                    <CTableDataCell>{data?.categoryName}</CTableDataCell>
                                    <CTableDataCell>{data?.cutOffScore}</CTableDataCell>
                                  </CTableRow>
                                )
                              })
                            ) : (
                              <CTableRow>
                                <CTableDataCell colSpan={6}>No Cut Off Detail found</CTableDataCell>
                              </CTableRow>
                            )}
                          </CTableBody>
                        </CTable>
                      </CCol>
                    )}
                  </div>
                </div>
                <div className="form-check">
                  <label className="mt-4 form-check-label" style={{ color: 'red' }}>
                    {GSteam?.counsellingName === 'UG'
                      ? `Note :- If you want to participate in the BAMS/BHMS counselling process, then
                    you have to produce valid category & NEET-UG Score certificate at the time of
                    document verification. The same should also be uploaded during the registration
                    process.`
                      : `Note :- One year compulsory Rotatory Internship shall be an essential pre-qualification
                    for eligibility for admission to MD/MS (Ayurveda) for the
                    academic session 2024-25. The Cut off date for completion of internship shall be
                    as per notification.`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tostify />
    </>
  )
}

export default OnboardingRegistration
