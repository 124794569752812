import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './Reducers/rootReducer'

const reduxStore = configureStore({
  reducer: rootReducer,
  // You can customize middleware if needed, but thunk is included by default
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customMiddleware)
})

export default reduxStore
