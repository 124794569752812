import { atom } from 'recoil';

export const globalStream = atom({
  key: 'newStream',
  default: {
    counsellingName: '',
    neetRollNumber:'',
    email:'',
    phoneNumber:'',
    counsellingId:''
  }
});
