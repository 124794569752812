import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import logo from '../../../assets/images/logo.svg'
import { CSpinner, CButton } from '@coreui/react'
import { useRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import { verifyOTP, resendOTP } from 'src/services/Registration/RegisterationService'
import { showToast } from 'src/utils/Tostify'
import Header from 'src/components/header/Header'

const OtpScreen = () => {
  const otpObject = { id: '', OTP: '' }
  const [formData, setFormData] = useState(otpObject)
  const [showResendButton, setShowResendButton] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loader, enableLoader] = useRecoilState(loadingState)
  const [countdown, setCountdown] = useState(60)
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = location.state || {}
  const [validationErrors, setValidationErrors] = useState({ OTP: '' })

  const handleCheckChange = (e) => setIsChecked(e.target.checked)

  const handleResendOTP = async (rollno) => {
    try {
      let rollStr = rollno.toString()
      const response = await resendOTP(rollStr)
      if (response.success) {
        showToast('OTP sent successfully', 'success')
      } else {
        showToast('OTP Failure')
      }
    } catch (error) {
      showToast(error.message)
    }
  }

  const handleResend = async () => {
    setCountdown(60)
    try {
      setShowResendButton(false)
      if (GSteam.neetRollNumber !== '') {
        await handleResendOTP(GSteam.neetRollNumber)
      }
    } catch (error) {
      showToast(error.message)
    }
  }

  const validateForm = () => {
    let errors = {}
    let isValid = true
    if (!formData.OTP) {
      errors.OTP = 'Please provide OTP'
      isValid = false
    }
    setValidationErrors(errors)
    return isValid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    enableLoader(true)

    if (validateForm()) {
      formData.id = id
      setIsSubmitting(true)
      const response = await verifyOTP(formData)
      if (response.success) {
        setDetails(response.body)
        setLoading(true)
        enableLoader(false)
        setIsSubmitting(false)
        navigate('/registration-validation', { state: { det: response.body } })
      } else {
        setIsSubmitting(false)
        showToast('Invalid OTP ', 'error')
      }
    } else {
      enableLoader(false)
    }
  }

  useEffect(() => {
    let timer = null
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else {
      setShowResendButton(true)
    }
    return () => clearTimeout(timer)
  }, [countdown])

  const handleOTPChange = (e) => {
    const { value } = e.target
    if (/^[0-9]{0,4}$/.test(value) || value === '') {
      setFormData({ ...formData, OTP: value === '' ? '' : parseInt(value) })
    }
  }

  return (
    <>
      <Header />
      <div
        className="mt-2 d-flex justify-content-center align-items-center"
        style={{ fontWeight: 'bold', fontSize: '25px' }}
      >
        Online Application Form For Admission To {GSteam.counsellingName} 2024-25
      </div>
      <div className="card ms-5 me-5 mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <th>Instruction Details</th>
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="d-flex flex-column align-items-left">
                <label style={{ color: 'red' }}>
                  <tr>1. OTP has been Genrated.</tr>
                  <tr>
                    2. Application Id and Password will be sent on your Registration email id and
                    mobile Number.
                  </tr>
                </label>
              </div>
              <div className="row justify-content-center">
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <div className="col-12 mt-3 text-center">
                    <input
                      type="text"
                      className={`form-control ${validationErrors.OTP ? 'is-invalid' : ''}`}
                      id="OTP"
                      placeholder="Enter OTP"
                      value={formData.OTP}
                      onChange={handleOTPChange}
                    />
                    {validationErrors.OTP && (
                      <div className="invalid-feedback">{validationErrors.OTP}</div>
                    )}
                    <div className="mt-2">
                      <button
                        type="submit"
                        className="mt-2 btn btn-sm btn-success text-white"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </button>
                      {showResendButton && (
                        <button
                          className="mt-2 ms-2 btn btn-sm btn-danger text-white"
                          type="button"
                          onClick={handleResend}
                        >
                          Resend
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {countdown > 0 && (
                  <div className="row justify-content-center mt-2">
                    <div className="col-sm-4 d-flex justify-content-center text-uppercase">
                      <p>Resend OTP Code After: {countdown} seconds</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default OtpScreen
