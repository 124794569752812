export async function getScreenRoles(id) {
  try {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/fetchScreens?roleId=${id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const content = await rawResponse.json()

    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  } catch (error) {
    console.error('Error fetching roles:', error)
    return { body: [], success: false, message: 'An error occurred while fetching roles' }
  }
}

export async function saveScreenMapping(roleId, screenIds) {
  const token = localStorage.getItem('token')
  const url = `${
    process.env.REACT_APP_API
  }/v1/api/saveScreenMapping?roleId=${roleId}&screenIds=${screenIds.join(',')}`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.errorMessage }
  }
}
