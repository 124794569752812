import React from 'react';
import PropTypes from 'prop-types';
const ErrorScreen = ({ errorMessage }) => {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f8d7da',
            color: '#721c24',
            textAlign: 'center',
        }}>
            <h1 style={{ fontSize: '2em', marginBottom: '1em' }}>Oops! Something went wrong.</h1>
            <p>{errorMessage}</p>
        </div>
    );
};

ErrorScreen.propTypes = {
    errorMessage: PropTypes.string.isRequired // Ensure errorMessage is a required string
};

export{ErrorScreen};
