import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import { getScreenRoles, saveScreenMapping } from 'src/services/Admin/ScreenMapping'

const ScreenMapping = () => {
  const location = useLocation()
  const roleId = location.state?.roleId
  const [checkboxState, setCheckboxState] = useState({})
  const [webScreens, setWebScreens] = useState([])

  const [loader, enableLoader] = useRecoilState(loadingState)
  const navigate = useNavigate()

  const handleCheckboxChange = (screenNameId, subSection) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [screenNameId]: {
        ...(prevState[screenNameId] || {}),
        [subSection]: !prevState[screenNameId]?.[subSection],
      },
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    enableLoader(true)
    try {
      const requiredKeys = Object.entries(checkboxState)
        .filter(([_, value]) => Object.values(value).some(Boolean))
        .map(([key]) => key)

      if (requiredKeys.length === 0) {
        console.log('No screens selected')
        enableLoader(false)
        return
      }

      const response = await saveScreenMapping(roleId, requiredKeys)

      if (response.success) {
        // console.log('Screen mapping saved successfully')
        // Optionally, you can reset the checkboxState or navigate to a different page
        setCheckboxState({})
        navigate('/Role-Management')
      } else {
        console.error('Error saving screen mapping:', response.message)
      }
    } catch (error) {
      console.log('Error', error)
    } finally {
      enableLoader(false)
    }
  }

  useEffect(() => {
    enableLoader(true)
    const getScreens = async () => {
      try {
        const {
          body: { webScreens },
        } = await getScreenRoles(roleId)
        setWebScreens(webScreens)
      } catch (error) {
        console.error('Error fetching Screens:', error)
      } finally {
        enableLoader(false)
      }
    }
    getScreens()
  }, [roleId])

  useEffect(() => {
    const updatedCheckboxState = { ...checkboxState }
    webScreens.forEach((screen) => {
      if (screen.subSections.length === 0) {
        // If no subsections, use screen.screenNameId and its selected property
        const isChecked = screen.selected === true
        // Update the checkbox state for the current screen if necessary
        updatedCheckboxState[screen.screenNameId] = {
          ...(updatedCheckboxState[screen.screenNameId] || {}),
          [screen.name.toLowerCase()]: isChecked,
        }
      } else {
        screen.subSections.forEach((subSection) => {
          const isChecked =
            checkboxState[subSection.screenNameId]?.[subSection.name.toLowerCase()] ||
            subSection.selected === true
          // Update the checkbox state for the current subSection if necessary
          updatedCheckboxState[subSection.screenNameId] = {
            ...updatedCheckboxState[subSection.screenNameId],
            [subSection.name.toLowerCase()]: isChecked,
          }
        })
      }
    })

    setCheckboxState(updatedCheckboxState)
  }, [webScreens])

  return (
    <div className="card mx-auto">
      <div className="card-header text-white" style={{ backgroundColor: '#3C4B64' }}>
        <div className="d-flex align-items-center justify-content-between">
          <span>Screen Mapping</span>
        </div>
      </div>

      <div className="card-body">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            {webScreens.length > 0 ? (
              webScreens.map((screen, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    {screen.subSections.length > 0 ? (
                      <div className="card mb-2 p-2">
                        <h5>{screen.name}</h5>
                        <div className="d-flex gap-2 px-2 flex-wrap">
                          {screen.subSections.map((subSection) => (
                            <div key={`${subSection.screenNameId}-${subSection.name}`}>
                              <input
                                type="checkbox"
                                className="me-1"
                                name={subSection.name.toLowerCase()}
                                id={`${subSection.screenNameId}-${subSection.name.toLowerCase()}`}
                                checked={
                                  checkboxState[subSection.screenNameId]?.[
                                    subSection.name.toLowerCase()
                                  ] || false
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    subSection.screenNameId,
                                    subSection.name.toLowerCase(),
                                  )
                                }
                              />

                              <label
                                htmlFor={`${
                                  subSection.screenNameId
                                }-${subSection.name.toLowerCase()}`}
                              >
                                {subSection.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="card mb-2 p-2">
                        <h5>{screen.name}</h5>
                        <div>
                          <input
                            type="checkbox"
                            className="me-1"
                            name={screen.name.toLowerCase()}
                            id={`${screen.screenNameId}-${screen.name.toLowerCase()}`}
                            checked={
                              checkboxState[screen.screenNameId]?.[screen.name.toLowerCase()] ||
                              false
                            }
                            onChange={() =>
                              handleCheckboxChange(screen.screenNameId, screen.name.toLowerCase())
                            }
                          />
                          <label htmlFor={`${screen.screenNameId}-${screen.name.toLowerCase()}`}>
                            {screen.name}
                          </label>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              })
            ) : (
              <div className="d-flex justify-content-center">
                <strong className="text-center ">Data not available</strong>
              </div>
            )}
          </div>

          {webScreens.length > 0 && (
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary ms-2"
            onClick={() => navigate('/Role-Management')}
          >
            Back
          </button>

          {webScreens.length === 0 && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate('/Role-Management')}
            >
              Back
            </button>
          )}
        </form>
      </div>
    </div>
  )
}

export default ScreenMapping
