import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { loadingState } from 'src/atom/LoadingState'
import {
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from '@coreui/react'

import clg_icon from '../../assets/images/college_icon_1.png'
import { fetchCandidatePaymentDetails } from 'src/services/Payment/PaymentService'
import { allocationDashboard } from 'src/services/CollegeLogin/CollegeLogin'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const collegeObj = {
    id: '',
    applicantId: '',
    courseId: '',
    reportingName: '',
    pageNumber: '',
    pageSize: '',
    sortOrder: '',
  }

  const [loader, enableLoader] = useRecoilState(loadingState)
  const [formData, setFormData] = useState([])
  const navigate = useNavigate()

  const fetchDashboard = async () => {
    enableLoader(true)
    var details = async () => {
      const response = await allocationDashboard()
      // console.log(response)  
      if (response.success) {
        setFormData(response.body)
        // setPaymentDetails(response.body)
        // setCandidateDetails(response.body.candidateBasicDetailsVO)
        enableLoader(false)
      } else {
        enableLoader(false)
      }
    }
    details()
  }

  const dummyRowData = {
    courses: [
      {
        courseName: 'MD - Anaesthesiology',
        courseData: [
          {
            serialNumber: 1,
            courseCode: 'ANES',
            allocated: 25,
            reported: 20,
            notReported: 5,
            total: 25,
          },
          {
            serialNumber: 2,
            courseCode: 'ANES',
            allocated: 22,
            reported: 18,
            notReported: 4,
            total: 22,
          },
          {
            serialNumber: 3,
            courseCode: 'ANES',
            allocated: 20,
            reported: 17,
            notReported: 3,
            total: 20,
          },
          {
            serialNumber: 4,
            courseCode: 'ANES',
            allocated: 18,
            reported: 15,
            notReported: 3,
            total: 18,
          },
          {
            serialNumber: 5,
            courseCode: 'ANES',
            allocated: 15,
            reported: 12,
            notReported: 3,
            total: 15,
          },
        ],
      },
      {
        courseName: 'MS - General Surgery',
        courseData: [
          {
            serialNumber: 1,
            courseCode: 'GS',
            allocated: 30,
            reported: 28,
            notReported: 2,
            total: 30,
          },
          {
            serialNumber: 2,
            courseCode: 'GS',
            allocated: 25,
            reported: 22,
            notReported: 3,
            total: 25,
          },
          {
            serialNumber: 3,
            courseCode: 'GS',
            allocated: 20,
            reported: 18,
            notReported: 2,
            total: 20,
          },
          {
            serialNumber: 4,
            courseCode: 'GS',
            allocated: 18,
            reported: 15,
            notReported: 3,
            total: 18,
          },
          {
            serialNumber: 5,
            courseCode: 'GS',
            allocated: 15,
            reported: 12,
            notReported: 3,
            total: 15,
          },
        ],
      },
    ],
  }

  const calculateTotals = (courseData) => {
    const totals = courseData.reduce(
      (acc, curr) => ({
        allocated: acc.allocated + curr.allocated,
        reported: acc.reported + curr.reported,
        notReported: acc.notReported + curr.notReported,
        total: acc.total + curr.total,
      }),
      { allocated: 0, reported: 0, notReported: 0, total: 0 },
    )

    return totals
  }

  useEffect(() => {
    fetchDashboard()
  }, [])

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className="card mb-3" style={{ maxWidth: '200px' }}>
            <div className="row g-0">
              <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
                <img src={clg_icon} className="img-fluid" alt="Image" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Total Seats</h5>
                  <p className="card-text">{formData.totalSeats}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3" style={{ maxWidth: '200px' }}>
            <div className="row g-0">
              <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
                <img src={clg_icon} className="img-fluid" alt="Image" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Allocated</h5>
                  <p className="card-text">{formData.allocatedSeats}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3" style={{ maxWidth: '200px' }}>
            <div className="row g-0">
              <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
                <img src={clg_icon} className="img-fluid" alt="Image" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Joined</h5>
                  <p className="card-text">{formData.joined}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3" style={{ maxWidth: '200px' }}>
            <div className="row g-0">
              <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
                <img src={clg_icon} className="img-fluid" alt="Image" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Vacant</h5>
                  <p className="card-text">{formData.vacant}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mx-auto">
          <div className="card-header text-white" style={{ backgroundColor: '#3C4B64' }}>
            <div className="d-flex align-items-center justify-content-between">
              <span className="mr-auto">Allocation Dashboard</span>
            </div>
          </div>
          {/* stats bars */}
          {/* <div className="card-body p-10 d-flex flex-wrap justify-content-around align-items-center">
        {statsList.map((item) => {
          return <Stats key={item.id} {...item} />
        })}
      </div> */}

          {/* Table */}
          <div className="card-body">
            <CTable
              responsive
              className="table table-striped table-bordered table-hover table-responsive-md"
            >
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col" colSpan="6" className="text-center">
                    Course Wise & Category wise Allocation Report
                  </CTableHeaderCell>
                </CTableRow>
                <CTableRow className="text-center">
                  <CTableHeaderCell scope="col" colSpan="3">
                    As per allocations and reporting done till now
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" colSpan="3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        navigate('/College-Allocation')
                      }}
                    >
                      View Details
                    </button>
                    &nbsp;&nbsp;
                    <button className="btn btn-primary"
                       onClick={() => {
                        navigate('/ReportGenration')
                      }}>Generate Reporting Letter</button>
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell>S.No</CTableHeaderCell>
                  <CTableHeaderCell>Course</CTableHeaderCell>
                  <CTableHeaderCell>Total Allocated</CTableHeaderCell>
                  <CTableHeaderCell>Total Not Allocated</CTableHeaderCell>
                  <CTableHeaderCell>Total Reported</CTableHeaderCell>
                  <CTableHeaderCell>Total Not Reported</CTableHeaderCell>
                </CTableRow>

                {formData.courseDetail && formData.courseDetail.length > 0 ? (
                  formData.courseDetail.map((data, dataIndex) => (
                    <CTableRow key={`${dataIndex}-${dataIndex}`}>
                      <CTableHeaderCell scope="row">{dataIndex + 1}</CTableHeaderCell>
                      <CTableDataCell>{data.courseName}</CTableDataCell>
                      <CTableDataCell>{data.totalAllocated}</CTableDataCell>
                      <CTableDataCell>{data.totalNotAllocated}</CTableDataCell>
                      <CTableDataCell>{data.totalReported}</CTableDataCell>
                      <CTableDataCell>{data.totalNotReported}</CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow>
                    <td colSpan="6">No details found</td>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
