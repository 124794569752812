export async function login(credentials) {
  try {
    const rawResponse = await fetch(process.env.REACT_APP_API + `/authenticate`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })

    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { data: content, success: true, message: 'User logged in successfully' }
    } else {
      return { data: content.errorMessage, success: false, message: content.message }
    }
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
      success: false,
      message: 'Unable to connect to the backend. Please try again later!',
    }
  }
}

export async function changePassword(obj) {
  try {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/updatePassword/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(obj),
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { data: content, success: true, message: 'Password changed successfully' }
    } else {
      return { data: undefined, success: false, message: content.errorMessage }
    }
  } catch (e) {
    console.error(e)
    return { data: undefined, success: false, message: 'Something went wrong !' }
  }
}

export const isLoggedIn = () => {
  const token = localStorage.getItem('token')
  return !!token
}

export async function validate() {
  const token = localStorage.getItem('token')
  try {
    const rawResponse = await fetch(process.env.REACT_APP_API + `/validate`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { data: content, success: true, message: 'User logged in successfully' }
    } else {
      return { data: content.detail, success: false, message: content.detail }
    }
  } catch (e) {
    console.error(e)
    return { data: undefined, success: false, message: 'Something went wrong !' }
  }
}

