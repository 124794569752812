export async function getRoles() {
  try {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/fetch/all/rolesOfCounselling`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const content = await rawResponse.json()

    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  } catch (error) {
    console.error('Error fetching roles:', error)
    return { body: [], success: false, message: 'An error occurred while fetching roles' }
  }
}
