import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import { getRoles } from 'src/services/Admin/RoleMangement'

import {
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilTags } from '@coreui/icons'
import { useNavigate } from 'react-router-dom'

const RoleManagement = () => {
  // Add state for modal
  const [showRoleModal, setShowRoleModal] = useState(false) // Add state for modal
  // Add state for selected role
  const [roles, setRoles] = useState([])
  const [loader, enableLoader] = useRecoilState(loadingState)
  const navigate = useNavigate()

  const HandleAction = (e, role) => {
    const { roleId } = role
    navigate(`/Screen-Mapping`, { state: { roleId } })
  }

  const HandleRoleModelAction = () => {
    // Show the modal
    setShowRoleModal(true)
  }

  const RoleModal = () => {
    return (
      <CModal visible={showRoleModal} onClose={() => setShowRoleModal(false)}>
        <CModalHeader>
          <CModalTitle>Create Role</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CButton color="primary" className="me-2">
            Edit
          </CButton>
          <CButton color="danger">Delete</CButton>
        </CModalBody>
      </CModal>
    )
  }

  useEffect(() => {
    enableLoader(true)
    const fetchRoles = async () => {
      try {
        const { body } = await getRoles()
        // console.log(body)
        setRoles(body)
      } catch (error) {
        console.error('Error fetching roles:', error)
      } finally {
        enableLoader(false)
      }
    }
    fetchRoles()
  }, [])

  return (
    <div className="card mx-auto">
      {/* <h1>Role Management</h1> */}

      <div className="card-header text-white" style={{ backgroundColor: '#3C4B64' }}>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <span>Role Management</span>
          <button
            className="btn btn-primary"
            onClick={(e) => HandleRoleModelAction(e)}
            style={{ cursor: 'pointer' }}
          >
            + Create Role
          </button>
        </div>
      </div>

      <div className="card-body">
        <CTable
          responsive
          className="table table-striped  table-bordered  table-hover table-responsive-sm table-responsive-md table-responsive-lg"
        >
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>S.No</CTableHeaderCell>
              <CTableHeaderCell>Role Name</CTableHeaderCell>
              <CTableHeaderCell>Role Type</CTableHeaderCell>
              <CTableHeaderCell>Actions</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {roles.length > 0 ? (
              roles.map((role, index) => {
                return (
                  <React.Fragment key={role.roleId}>
                    <CTableRow scope="col">
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{role.roleName}</CTableDataCell>
                      <CTableDataCell>{role.roleType}</CTableDataCell>
                      <CTableDataCell>
                        <CIcon
                          onClick={(e) => HandleAction(e, role)}
                          style={{ cursor: 'pointer' }}
                          icon={cilTags}
                        />
                      </CTableDataCell>
                    </CTableRow>
                  </React.Fragment>
                )
              })
            ) : (
              <CTableRow scope="row" className="text-center">
                <CTableDataCell colSpan="4">No Data Available</CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>

        <RoleModal />
      </div>
    </div>
  )
}

export default RoleManagement
