import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilValue } from 'recoil'
import logo from '../../../assets/images/logo.svg'
import { CSpinner, CButton } from '@coreui/react'
import { useRecoilState } from 'recoil'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import Header from 'src/components/header/Header'
const RegistrationInstruction = () => {
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  let counsellingName = useRecoilValue(globalStream).counsellingName

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }



  useEffect(() => {
    if (GSteam.counsellingName === 'UG') {
      setGlobalSteam((prevGlobalSteam) => ({
        ...prevGlobalSteam,
        counsellingName: 'UG',
      }))
    }
    if (GSteam.counsellingName === 'PG') {
      setGlobalSteam((prevGlobalSteam) => ({
        ...prevGlobalSteam,
        counsellingName: 'PG',
      }))
    }
  }, [GSteam.counsellingName])

  useEffect(() => {
    if (!counsellingName) {
     
      navigate('/homepage')
    }
  }, [counsellingName])

  console.log(`Instruction : ${counsellingName}`)
  // console.log(`Instruction : ${GSteam.counsellingName}`)
  return (
    <>
      <div className="container-fluid" style={{ padding: '10px 20px' }}>
        <Header />

        {/* <div
        className="mt-2 d-flex justify-content-center align-items-center"
        style={{ fontWeight: 'bold', fontSize: '25px' }}
      >
        Online Application Form For Admission To {GSteam.counsellingName } 
         2024-25
      </div> */}
        <div
          className="mt-2 d-flex justify-content-center align-items-center"
          style={{ fontWeight: 'bold', fontSize: '25px' }}
        >
          Online Application Form For Admission To{' '}
          {GSteam.counsellingName === 'UG' ? 'NEET UG' : 'AIAPGET'} courses 2024-25
        </div>

        <div className="card ms-5 me-5 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <th>Instruction Before Filling Application Form :</th>
              </div>
            </div>
            <hr />
            <div className="row mt-2">
              <div className="col-sm-12">
                <ul>
                  <li>
                    Please Keep NEET {GSteam.counsellingName} 2024 Score Card with you before
                    filling the details.
                  </li>
                  <li>
                    Candidate must read the notification carefully for admission to{' '}
                    {GSteam.counsellingName === 'UG' ? 'BAMS/BHMS ' : 'MD/MS (Ayurveda) '} for the
                    session 2024-2025 before filling the application form.
                  </li>
                  <li>
                    Any concealment of information or submission of wrong information if given by
                    the candidate shall be lead to the cancellation of the candidature without any
                    further Notice.
                  </li>
                  <li>
                    Please go through the Notification for admission to{' '}
                    {GSteam.counsellingName === 'UG' ? 'BAMS/BHMS ' : 'MD/MS (Ayurveda) '}
                    courses for session 2024-25 uploaded on the Homepage.
                  </li>
                  <li>
                    For any further information, candidates are advised to visit the website -
                    <a
                      href="https://www.skau.ac.in"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="blue-link"
                    >
                      www.skau.ac.in
                    </a>
                    .
                  </li>
                </ul>
                {/* <tr>
                  <th style={{ width: '8%', textAlign: 'center' }}> Note :- </th>
                  <td style={{ color: 'red' }}>
                    One year compuslory Rotatory Internship shall be an essential pre-qualification
                    for eligibility for admission to{' '}
                    {GSteam.counsellingName === 'UG' ? 'BAMS/BHMS ' : 'MD/MS (Ayurveda) '} for the
                    academic session 2024-25. The Cut off date for completion of internship shall be
                    as per notification.
                  </td>
                </tr> */}
                <div className="ms-3 form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="check1"
                    checked={isChecked}
                    onChange={handleCheckChange}
                    required
                  />
                  <label className="ms-2 form-check-label" htmlFor="check1">
                    Yes, I have read related Govt. of Haryana Notification for the year 2024 and
                    understood all the instructions.
                  </label>
                </div>
                <hr />
                <div className="m-3 text-left form-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      navigate('/onboarding-registration')
                    }}
                    disabled={!isChecked || loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      'Proceed'
                    )}
                  </button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`.blue-link {
            color: blue;
            text-decoration: underline;`}
      </style>
    </>
  )
}

export default RegistrationInstruction
